<template>
  <section class="agent-buttons">
    <a class="primary-button primary-button__classic" :href="agentClassic" target="_blank">agent classic</a>
    <a class="primary-button primary-button__new" :href="agentNew" target="_blank">agent new</a>
    <img  class="brand" src="../assets/images/logo-white.png" alt="wagerInternational">
  </section>
</template>

<script>
  export default {
    name: "AgentsButtons",
    setup() {
      const agentClassic = "https://agents.thirddown.live/";
      const agentNew = "https://adm.thirddown.live/home/landing";
      return {
        agentClassic,
        agentNew,
      }
    },
  };
</script>
